// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@hotwired/turbo-rails')
require('@rails/activestorage').start()
// require('channels')

import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs'
window.Alpine = Alpine

document.addEventListener('turbo:before-render', (event) => {
  // this check is performed on "new" pages
  if (window.Alpine) {
    event.detail.newBody.classList.remove('noalpine')
    event.detail.newBody.querySelector('#app-loader')?.remove()
  }
})

document.addEventListener('alpine:init', () => {
  document.body.classList.remove('noalpine')
  window.Alpine.data('appLoader', () => ({
    loading: true,
    init() {
      document.documentElement.scrollTop = 0
      setTimeout(() => {
        this.loading = false
      }, 0)
    },
  }))
})

Alpine.start()
